.chat-visualizer-container .button-template {
  border: 1px solid rgba(0, 0, 0, 0.1);;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.11);

  .text {
    padding: 8px 10px;
    background-color: #F1F0F0;
    white-space: pre-wrap;
    color: #555;
  }
}