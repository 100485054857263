@import "../../stylesheets/var.scss";

.chat-visualizer-container .row {
  display: flex;
  align-items: flex-start;
  padding: 8px;

  .content-info {
    display: flex;
    align-items: flex-end;
    flex-shrink: 0;
    max-width: calc(80% - 48px);

    @media only screen and (min-width: 801px) {
      max-width: 360px;
    }

    @media only screen and (max-width: 500px) {
      max-width: 100%;
    }

    &.noTimeDisplay {
      align-items: center;
    }

    .error-icon {
      padding: 0 4px 2px 4px;
      color: hsl(8, 74%, 49%);
      width: 16px;
      height: 16px;

      &.retry {
        cursor: pointer;
      }
    }

    .time-display {
      font-size: 0.694em;
      color: #555;
      cursor: default;
      width: 32px;
      padding-left: 4px;
    }

    :visited, :link {
      color: $theme-color;
    }

    >.content {
      width: 100%;

      >.raw {
        font-family: 'Courier New', Courier, monospace;
      }
    }
  }

  &.agent, &.bot {
    justify-content: flex-end;

    .content-info >.content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .profile-pic {
    flex-shrink: 0;
    background-color: black;
    width: 50px;
    height: 50px;
    background: center no-repeat;
    background-size: contain;
    border-radius: 50%;
    margin-right: 8px;
  }

  .message-bubble {
    max-width: 100%;
    padding: 8px 10px;
    border-radius: 8px;
    display: inline-block;
    overflow: hidden;
    vertical-align: top;
    box-shadow: $message-bubble-box-shadow;

    &.text {
      white-space: pre-wrap;
      word-break: break-word;
      a, span {
        color: $theme-color;
      }
      .text-wrapper {
        .read-more {
          color: #0063f0;
          cursor: pointer;
        }
      }
    }

    :visited, :link {
      color: $theme-color-dark;
    }
  }

  .whatsapp-cloud {
    box-shadow: none;
  }
}

.chat-visualizer-container.bot-only .row {
  .content-info {
    max-width: 100%;
    width: 100%;

    &>.content {
      flex-shrink: 0;
      max-width: 100%;
    }
  }
}
