.chat-visualizer-container .row .message-bubble.animation {
  padding: 0;
  max-width: 70%;
  img {
    width: 100%;
    vertical-align: bottom;
  }

  .caption {
    padding: 8px 10px;
  }

  &.placeholder {
    width: 100px;
    padding: 16px;
    min-height: 100px;
    background-color: #F1F0F0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .icon {
      font-size: 50px;
      color: #ccc;
      margin-bottom: 8px;
    }

    span {
      font-size: 0.833333em;
      text-align: center;
    }
  }
}

.chat-visualizer-container .row.user .message-bubble.animation {
  &.placeholder {
    background-color: #0084FF;

    .icon {
      color: white;
    }
  }
}