.chat-visualizer-container .quick-replies-template {
  width: 100%;
  margin-top: 0px;
  // height: 42px;
  position: relative;

  .quick-replies-container {
    padding: 8px;
    display: flex;
    justify-content: flex-start;

    .quick-replies-wrapper {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
    }
  }
}