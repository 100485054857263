.chat-visualizer-container .list-template {
  border: 1px solid rgba(0, 0, 0, 0.1);;
  border-radius: 8px;
  background-color: white;
  overflow: hidden;
  box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.11);

  a {
    text-decoration: none;

    &:visited, &:link {
      color: inherit;
      text-decoration: none;
    }
  }
  .list-container {
    .list-item-container {
      padding: 12px;
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);;

      &.cover {
        position: relative;
        padding: 0;
        border: none;
        background: center no-repeat;
        background-size: cover;

        .overlay {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: rgba(0, 0, 0, 0.25);
          pointer-events: none;
        }

        &:after {
          display: block;
          width: 100%;
          content: "";
          padding-top: 52.35602094%;
        }

        .title, .subtitle, .link {
          color: white !important;
        }
      }

      .list-item-img {
        flex-shrink: 0;
        margin-left: 16px;
        width: 64px;
        height: 64px;

        a {
          width: 100%;
          height: 100%;
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      &:last-child {
        border-bottom: none;
      }

      .title {
        a {
        text-decoration: underline;

        &:visited, &:link {
          color: -webkit-link;
          text-decoration: underline;
        }
      }
      }
    }
  }

  .list-buttons-container {
    .chat_btn {
      margin: 0 0 0 0;
      padding: 6px 10px;
      color: #0084FF;
    }
  }
}