

.chat-visualizer-container .carousel-template {
  position: relative;
  max-width: 302px;
  >.arrow {
    position: absolute;
    border: 1px solid #222;
    background: white;
    height: 32px;
    top: 50%;
    border-radius: 3px;
    width: 34px;
    opacity: .4;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon {
      font-size: 30px;
    }

    &.prev {
      left: 0;
    }

    &.next {
      right: 0;
    }

    &:hover, &:active {
      opacity: .7;
    }
  }
  .slick-slider {
    .slick-list .slick-slide {
      padding: 0 8px;
      box-sizing: border-box;
    }
  }

  .carousel-item {
    // width: 300px;
    border: 1px solid rgba(0, 0, 0, 0.1);;
    border-radius: 8px;
    outline: none;
    overflow: hidden;
    box-shadow: 1px 1px 3px 0px rgba(0, 0, 0, 0.11);
    .image-container {
      position: relative;
      display: block;

      .image {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: center no-repeat;
        background-size: cover;
      }

      &:after {
        display: block;
        width: 100%;
        content: "";
        padding-top: 52.35602094%;
      }

      &.square {
        &:after {
          display: block;
          width: 100%;
          content: "";
          padding-top: 100%;
        }
      }
    }

    .content {
      border-top: 1px solid rgba(0, 0, 0, 0.1);
      .info {
        padding: 8px 12px;
        .title {
          font-weight: 500;
          margin-bottom: 2px;
          white-space: pre-wrap;
        }

        .subtitle {
          font-size: 0.83333em;
          color: #777;
          margin-bottom: 2px;
          white-space: pre-wrap;
        }

        .link {
          font-size: 0.83333em;
          color: #999;
        }
      }
    }
  }
}