$primary: hsl(215, 61%, 41%);
$secondary: hsl(358, 100%, 67%);

$black: #2e2e2e;
$white: #ffffff;

$success: hsl(94, 48%, 56%);
$error: hsl(8, 74%, 49%);
$warning: hsl(46, 100%, 61%);

$gray-12: #1d1d1d;
$gray-11: #272727;
$gray-10: #2e2e2e;
$gray-9: #3c3c3c;
$gray-7:#626262;
$gray-6: #707070;
$gray-5: #929292;
$gray-4: #a5a5a6;
$gray-3: #bbbbbc;
$gray-2: #cccccc;
$gray-15: #e6e6e6;
$gray-1: #e8e7e8;
$gray-05: #f0f0f0;
$gray-02: #f7f7f7;
$gray-0: #fafafa;

$space-xxxs: 0.25em;
$space-xxs:  0.375em;
$space-xs:   0.5em;
$space-sm:   0.75em;
$space-md:   1.5em;
$space-lg:   2em;
$space-xl:   3.25em;
$space-xxl:  5.25em;
$space-xxxl: 8.5em;

$font-primary: sans-serif;
$font-secondary: serif;

/* set base values */
$text-base-size: 1em;
$text-scale-ratio: 1.2;

/* type scale */
$text-xs: calc(1em / ($text-scale-ratio * $text-scale-ratio));
$text-sm: calc(1em / $text-scale-ratio); 
$text-md: 1em * $text-scale-ratio;
$text-lg: 1em * ($text-scale-ratio * $text-scale-ratio);
$text-xl: 1em * ($text-scale-ratio * $text-scale-ratio * $text-scale-ratio);
$text-xxl: 1em * ($text-scale-ratio * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio);
$text-xxxl: 1em * ($text-scale-ratio * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio);
$text-xxxxl: 1em * ($text-scale-ratio * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio * $text-scale-ratio);

/* line-height */
$heading-line-height: 1.2;
$body-line-height: 1.4;

$theme-color: #0063f0;
$theme-color-light: #1D70A2;
$theme-color-pale: #6DAEDB;
$theme-color-solid: #1B4353;
$theme-color-dark: #173753;
$theme-color-hover: #0055ff;
$theme-color-highlight: #66acff;
$theme-color-light-focus: #ecf3ff;
$theme-text: #3366ca;
$theme-icon: #3366ca;
$selector-focus: #2684ff;
$selector-border: hsl(0,0%,80%);
$theme-gradient: linear-gradient(90deg, #00a1ff, #0055ff);
$button-shadow: 0 1px 4px 1px transparentize(#333, 0.9);
$card-shadow: 0 1px 12px 0 rgba(0, 0, 0, 0.03);
$tag-background: $theme-color-dark;
$light-button-bg: #f8f8f8;
$light-button-icon: #b9b9b9;
$light-button-text: #979797;
$dark-button-icon: #979797;
$dark-button-text: #777777;
$dark-text: #333333;
$light-text: #777777;
$danger-text: #cc0000;
$danger-icon: #fb0d0d;
$danger-button-bg: #942b2b;
$stable-text: #00aa00;
$stable-icon: #00d100;
$warning-text: #000000;
$warning-icon: #f1f10d;
$grey-dropdown-bg: #f2f2f2;
$placeholder-text: rgba(0, 0, 0, 0.3);
$dark-icon: #333333;
$light-background: #e3e5e8;
$pale-background: #f0f2f7;
$paler-background: #f8f8f8;
$super-paler-background: #f3f3f3;
$pale-button-bg: #fafafa;
$pale-button-border: hsl(0, 0%, 80%);
$pale-border: #e4e4e4;
$segment-separator: #ebebeb;
$font-awesome: "Font Awesome 5 Free solid";
$message-bubble-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.11);

$main-font: "Open Sans", "Arail", sans-serif;
$border-color: #ededed;
$border-color-dark: #dbdbdb;
$border-color-light: #dcdcdc;
$header-text-color: white;

$modal-box-shadow: 0 7px 8px -4px rgba(0,0,0,0.2), 0 13px 19px 2px rgba(0,0,0,0.14), 0 5px 24px 4px rgba(0,0,0,0.12);
$modal-border-radius: 4px;
