@import "../../../stylesheets/var.scss";

.radiate-theme.search-bar-container {
  position: relative;
  height: 38px;
  width: 100%;
  
  .text-input {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $selector-border;
    padding-left: 10px;
    font-family: $main-font;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.85rem;

    &:focus {
      outline: none;
      border: 2px solid $selector-focus;
    }

    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
      color: #808080;
    }
    &::-moz-placeholder { /* Firefox 19+ */
      color: #808080;
    }
    &:-ms-input-placeholder { /* IE 10+ */
      color: #808080;
    }
    &:-moz-placeholder { /* Firefox 18- */
      color: #808080;
    }
  }

  .icon {
    color: #979797;
    position: absolute;
    top: 12px;
    left: 10px;
    font-size: 14px;
  }
}
