.chat-visualizer-container .media-template {
  border: 1px solid rgba(0, 0, 0, 0.1);;
  border-radius: 8px;
  overflow: hidden;

  .media {
    .placeholder {
      padding: 16px;
      min-height: 200px;
      background-color: #F1F0F0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .icon {
        font-size: 80px;
        color: #ccc;
      }

      span {
        font-size: 0.833333em;
      }
    }
  }
}