@import "../../../stylesheets/var.scss";

.modal-fixed-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9000;
  pointer-events: none;
  transition: all 0.2s linear;
  opacity: 0;
  overscroll-behavior: none;

  &.initial-show {
    opacity: 1;
    pointer-events: all;
    transition: all 0.2s linear;

    .modal-container {
      background-color: rgba(0, 0, 0, 0.5);
    }
  }

  &.radiate-modal {
    &-enter {
      opacity: 0;
      pointer-events: none;
    }

    &-enter-active {
      opacity: 1;
      pointer-events: all;
      transition: all 0.2s linear;

      .modal-container {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &-enter-done {
      opacity: 1;
      pointer-events: all;
      .modal-container {
        background-color: rgba(0, 0, 0, 0.5);
      }
    }

    &-exit {
      opacity: 1;
      pointer-events: none;
    }
    &-exit-active {
      opacity: 0;
      transition: all 0.2s linear;
    }
  }

  .modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.2s linear;
    // background-color: white;
    // box-shadow: $modal-box-shadow;
    // border-radius: $modal-border-radius;
    // overflow: hidden;
  }
}
