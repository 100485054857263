@import "../../../stylesheets/var.scss";

.chat-visualizer-container .row .message-bubble.file {
  padding: 0;
  text-decoration: none;
  display: flex;
  align-items: center;
  flex-direction: column;

  >a {
    width: 100%;
    height: 100%;
    color: #f0f1f0;
    text-decoration: none;
  }

  @media only screen and (max-width: 801px) {
    width: 200px;
  }

  .file-file {
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-sizing: border-box;
    height: 132px;
    min-width: 132px;
    color: $pale-background;

    .icon {
      font-size: 50px;
      margin-bottom: 8px;
      color: #ffffff;
    }

    span, div {
      font-size: 0.833333em;
      text-align: center;
      word-break: break-word;
      padding: 0 4px;
      color: #555;
    }
  }

  .caption { 
    // background: #f9f9f9;
    padding: 8px 10px;
    word-break: break-word;
    width: calc(100% - 20px);
    line-height: 1.2rem;
    color: $gray-7;
  }
}

.chat-visualizer-container .row.user .message-bubble.file {
  .file-file {
    span {
      color: #ffffff;
    }
  }
}