.chat-visualizer-container .row .message-bubble.location {
  .icon {
    margin-right: 8px;
  }
  text-decoration: none;

  &:link, &:visited {
    color: inherit;
    text-decoration: none;
  }
}

.chat-visualizer-container .row.user .message-bubble.location {
  color: white;
}